import { useDisclosure } from "@chakra-ui/hooks";
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import { updatePassword } from "api/graphQL_users";
import Card from "components/card/custom";
import DivLoader from "components/divloader/DivLoader";
import InputField from "components/fields/InputField";
import React from "react";
import { ModalFooter, ModalHeader } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { MdHighlightOff } from "react-icons/md";
const ChangePasswordModal = () => {
  const rootForm = {
    old_password: "",
    new_password: "",
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = React.useState<boolean>();
  const [formValues, setFormValues] = React.useState<any>(rootForm);
  const [formValuesErr, setFormValuesErr] = React.useState<any>(rootForm);
  const [isCloseModal, setIsCloseModal] =  React.useState<boolean>(false);
  
  const handleClose = () => {
    setIsCloseModal(true)
    setFormValues(rootForm);
    setFormValuesErr(rootForm);
    setTimeout(() => {
      setIsCloseModal(false)
      onClose();
    }, 300);
  };
  function handleValueChange(e: any) {
    setFormValues((preVal: any) => {
      return { ...preVal, [e.target.id]: e.target.value };
    });
    setFormValuesErr((preVal: any) => {
      return { ...preVal, [e.target.id]: "" };
    });
  }
  function validateData() {
    if (formValues.old_password.length < 8) {
      setFormValuesErr((preval: any) => {
        return {
          ...preval,
          old_password: "Password must contain at least 8 characters",
        };
      });
      return false;
    }
    if (formValues.new_password.length < 8) {
      setFormValuesErr((preval: any) => {
        return {
          ...preval,
          new_password: "Password must contain at least 8 characters",
        };
      });
      return false;
    }
    return true;
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    let validate = validateData();
    if (validate) {
      try {
        setIsLoading(true);
        const result = await updatePassword(formValues);
        if(result.status == 'Error') {
          toast.error(result?.message ?? "Unable save user data");
        } else {
          toast.success("Password changed successfully");
          handleClose();
        }
      } catch (err: any) {
        if (err?.response?.status === 422) {
          toast.error("Please provide valid values");
        // } else {
          toast.error(err?.response?.data?.message ?? "Unable save user data");
        }
      } finally {
        setIsLoading(false);
      }
    }
  }

  return (
    <>
      <button
        onClick={() => {
          onOpen();
        }}
        className="w-fit rounded-xl border-2 border-indigo-200 bg-indigo-50 px-5 py-2 text-indigo-500 outline-none"
      >
        Change Password
      </button>
      <Modal isOpen={isOpen} onClose={() => {}}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <Card extra={`fixed top-0 right-0 h-full rounded-none max-w-[600px] sm:w-full flex flex-col justify-start !z-[1004] overflow-y-auto scrollbar-hide overflow-auto ${
              isOpen && !isCloseModal ? "card-enter" : "card-exit"
            }`}>
          <ModalHeader className=" flex py-[20px] mb-[20px] px-[30px] text-2xl font-bold bg-brand-500 text-white">
            <MdHighlightOff
              className="absolute right-7 top-6 h-8 w-8 cursor-pointer text-white"
              onClick={handleClose}
            />
            Change Password
          </ModalHeader>
          <ModalBody className="p-4">
              <div className="max-h-[calc(100vh-300px)] overflow-auto overflow-x-hidden px-[30px] scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-1">
                <InputField
                  variant="auth"
                  extra="mt-3 w-full"
                  label="Old Password*"
                  placeholder="********"
                  id="old_password"
                  type="password"
                  value={formValues?.old_password}
                  state={formValuesErr?.old_password ? "error" : ""}
                  errMessage={formValuesErr?.old_password}
                  onChange={handleValueChange}
                />
                <InputField
                  variant="auth"
                  extra="mt-3 w-full"
                  label="New Password*"
                  placeholder="********"
                  id="new_password"
                  type="password"
                  value={formValues?.new_password}
                  state={formValuesErr?.new_password ? "error" : ""}
                  errMessage={formValuesErr?.new_password}
                  onChange={handleValueChange}
                />
              </div>

              
            
          </ModalBody>
          <ModalFooter className="mt-[10px] p-4">
            <div className="mt-5 flex gap-2 px-[30px] pb-[30px]">
                <button
                  onClick={handleClose}
                  className="linear rounded-xl bg-gray-100 px-5 py-2 text-base font-medium text-navy-700 outline-none transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                >
                  Close
                </button>
                <button
                  onClick={handleSubmit}
                  className="linear rounded-xl bg-indigo-50 px-5 py-2 text-base font-medium text-indigo-500 hover:text-white outline-none transition duration-200 hover:bg-indigo-600/5 active:bg-indigo-700/5 dark:bg-indigo-400/10 dark:text-white dark:hover:bg-indigo-300/10 dark:active:bg-indigo-200/10"
                >
                  {isLoading ? (
                    <DivLoader className="h-6 w-6 border-indigo-500" />
                  ) : (
                    "Update Password"
                  )}
                </button>
              </div>
          </ModalFooter>
        </Card>
        
      </Modal>
    </>
  );
};
export default ChangePasswordModal;
