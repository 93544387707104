// import { getAllClients, getClients } from "api/clients";
import { getClients } from "api/graphQL_clients";
import { useEffect, useState, useContext, useRef } from "react";
import toast from "react-hot-toast";
import ClientsTable from "./components/ClientsList";
import CreateClientModal from "./components/CreateClientModal";
// import { getSingleRole } from "api/role";
import { getSingleRole } from "api/graphQL_role";
// import { getAllGateways } from "api/gateways";
import { getAllGateways } from "api/graphQL_gateways";
import { getId } from "utils/auth";
import { ClientContext } from "ClientProvider";
import { useLocation, useNavigate } from "react-router-dom";

export interface ClientRowObj {
  organization: string;
  name: string;
  description: string;
  website: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  country: string;
  postal_code: string;
  client_id: string;
  client_secret: string;
  org_id: string;
  org_name: string;
  client_gateway_active?: string
}

export type AllGatewayObj = {
  name: string;
  id: string;
};

const Organizations = () => {
  const [clients, setClients] = useState<ClientRowObj[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>();
  const [isDataLoading, setIsDataLoading] = useState<boolean>();
  const { singleRoleData, setSingleRoleData } = useContext(ClientContext);
  const [page, setPage] = useState<number>(1);
  const [totalpage, setTotalPage] = useState<number>(0);
  const [totalItems, setTotalItems] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(50);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [allGateways, setAllGateways] = useState<AllGatewayObj[]>([]);
  const [searchTexts, setSearchTexts] = useState<string>("");
  const location = useLocation();
  const navigate = useNavigate();

  const prevValues = useRef<{  searchTexts: string }>({
    searchTexts: "__init__", 
  });
  const fetchClients = async () => {
    let finalSearchText = ""
    if(location.state) {
      if (location.state?.search) {
        finalSearchText = location.state.search
        const searchTexts = location.state.search
        prevValues.current = { searchTexts };
        navigate(".", { replace: true, state: null }); 
      }
    } else {
        finalSearchText = searchTexts;
    }
    const params = { page: page, per_page: pageSize, searchTexts: finalSearchText };
    if (pageSize === null || pageSize === undefined) {
      setIsDataLoading(false);
      return;
    }
    setIsDataLoading(true);
    getClients(params)
    .then((data) => {
      setClients(data?.[0]);
      setTotalPage(data?.[1].total_pages);
      setTotalItems(data?.[1].total_items);
      setCurrentPage(data?.[1].current_page);
      setIsDataLoading(false);
    })
    .catch((err) => {
      setClients([]);
      setIsDataLoading(false);
    })
    .finally(() => {
    });
  };
  const fetchRole = () => {
    
    getSingleRole(getId())
      .then((data) => {
        setSingleRoleData(data ?? []);
      })
      .catch((err) => {
   
      })
      .finally(() => {});
  };
  const fetchAllGateways = () => {
    
    getAllGateways()
      .then((data) => {
        setAllGateways(data?.[0] ? data : []);
      })
      .catch((err) => {
      })
      .finally(() => {
        
      });
  };
  useEffect(() => {
    fetchAllGateways();
  }, []);
  const serchboxValueChange = async (searchTerm: string) => {
    const search_txt = searchTerm || "";
    if (search_txt !== searchTexts && searchTexts !== "") {
      await setSearchTexts(search_txt);
      setPage(1);
      setCurrentPage(1);
    } else {
      await setSearchTexts(search_txt);
    }
  };

  

  useEffect(() => {
    const hasChanged = prevValues.current.searchTexts !== searchTexts;
    if (!hasChanged) {
      return;
    }
    fetchClients();
    fetchRole();
    
  }, [page,searchTexts]);


  return (
    <>
     

      <div className="mt-3 grid grid-cols-1 gap-5 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-6">
        <CreateClientModal
          fetchClients={fetchClients}
          roleData={singleRoleData}
          allGateways={allGateways}
        />
      </div>
      <div className="mt-5">
        <ClientsTable
          tableData={clients}
          fetchClients={fetchClients}
          isLoading={isDataLoading}
          roleData={singleRoleData}
          page={page}
          setPage={setPage}
          totalpage={totalpage}
          totalItems={totalItems}
          currentPage={currentPage}
          pageSize={pageSize}
          allGateways={allGateways}
          setPageSize={setPageSize}
          onValueChange={serchboxValueChange}
        />
      </div>
    </>
  );
};

export default Organizations;
