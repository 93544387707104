import { useDisclosure } from "@chakra-ui/hooks";
import { Modal, ModalBody, ModalContent, ModalOverlay } from "@chakra-ui/modal";
import { deleteProfile } from "api/graphQL_users";
import Card from "components/card/custom";
import DivLoader from "components/divloader/DivLoader";
import InputField from "components/fields/InputField";
import React from "react";
import { ModalFooter, ModalHeader } from "react-bootstrap";
import { toast } from "react-hot-toast";
import { MdDelete, MdHighlightOff } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { clearAllCookie } from "utils/auth";
const DeleteAccountModal = () => {
  const rootForm = {
    password: "",
  };
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isLoading, setIsLoading] = React.useState<boolean>();
  const [formValues, setFormValues] = React.useState<any>(rootForm);
  const [formValuesErr, setFormValuesErr] = React.useState<any>(rootForm);
  const [isCloseModal, setIsCloseModal] =  React.useState<boolean>(false);
  
  const logout = () => {
    clearAllCookie();
    navigate("/auth/sign-in");
  };
  const handleClose = () => {
    setIsCloseModal(true)
    setFormValues(rootForm);
    setFormValuesErr(rootForm);
    setTimeout(() => {
      setIsCloseModal(false)
      onClose();
    }, 300);
  };
  function handleValueChange(e: any) {
    setFormValues((preVal: any) => {
      return { ...preVal, [e.target.id]: e.target.value };
    });
    setFormValuesErr((preVal: any) => {
      return { ...preVal, [e.target.id]: "" };
    });
  }
  function validateData() {
    let verifier: any;
    Object.keys(formValues).forEach((key: any) => {
    
        if (formValues[key as keyof any].length < 8) {
          verifier = {
            ...verifier,
            [key]: "Password must contain at least 8 characters",
          };
        }
      
    });

    setFormValuesErr(verifier);
    const hasEmptyValues = Object.entries(formValues).some(([key, value]) => {
      if (key === "password") {
        let val = (value as string).length;
        if (val < 8) {
          return val < 8;
        }
      } else {
        return value === "";
      }
    });
    if (hasEmptyValues) {
      return false;
    }
    return true;
  }

  async function handleSubmit(e: any) {
    e.preventDefault();
    let validate = validateData();
   
    if (validate) {
      try {
        setIsLoading(true);
        const response = await deleteProfile(formValues);
        console.log("validate",response)
        if(response.status == "Error") {
          toast.error(response?.message ?? "Please provide valid values");
        } else {
          toast.success("Account deleted successfully");
          handleClose();
          logout();
        }
        
      } catch (err: any) {
        if (err?.response?.status === 422) {
          toast.error("Please provide valid values");
        } else {
          toast.error(err?.response?.data?.message ?? "Unable save user data");
        }
      } finally {
        setIsLoading(false);
      }
    }
  }

  return (
    <>
      <button
        className="mt-3 flex w-fit items-center gap-1.5 rounded-lg bg-orange-600 px-3 py-1.5 text-sm font-medium text-white dark:text-white"
        onClick={onOpen}
      >
        <MdDelete className="h-4 w-4 cursor-pointer" />
        Delete Account
      </button>
      <Modal isOpen={isOpen} onClose={() => {}}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <Card extra={`fixed top-0 right-0 h-full rounded-none max-w-[600px] sm:w-full flex flex-col justify-start !z-[1004] overflow-y-auto scrollbar-hide overflow-auto ${
                  isOpen && !isCloseModal ? "card-enter" : "card-exit"
                }`}>
          <ModalHeader className=" flex py-[20px] mb-[20px] px-[30px] text-2xl font-bold bg-brand-500 text-white">
            <MdHighlightOff
              className="absolute right-7 top-6 h-8 w-8 cursor-pointer text-white"
              onClick={handleClose}
            />
             Delete Account
          </ModalHeader>
          <ModalBody className="p-4">
              <div className="px-[35px]">
                <p className="text-sm text-gray-600">
                  Are you sure you want to delete your account? This action is
                  irreversible and will result in the permanent deletion of all
                  your data.
                </p>
                <p className="my-2 text-sm text-gray-600">
                  Please consider the consequences before proceeding.
                </p>
              </div>
              <div className="max-h-[calc(100vh-300px)] overflow-auto overflow-x-hidden px-[30px] scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-full scrollbar-w-1">
                <InputField
                  variant="auth"
                  extra="mt-3 w-full"
                  label="Password*"
                  placeholder="********"
                  id="password"
                  type="password"
                  value={formValues?.password}
                  errMessage={formValuesErr?.password}
                  onChange={handleValueChange}
                />
              </div>

              
            
          </ModalBody>
          <ModalFooter className="mt-[10px] p-4">
          <div className="mt-5 flex gap-2 px-[30px] pb-[30px]">
                <button
                  onClick={handleClose}
                  className="linear rounded-xl bg-gray-100 px-5 py-2 text-base font-medium text-navy-700 outline-none transition duration-200 hover:bg-gray-200 active:bg-gray-300 dark:bg-white/10 dark:text-white dark:hover:bg-white/20 dark:active:bg-white/30"
                >
                  Close
                </button>
                <button
                  onClick={handleSubmit}
                  className="linear rounded-xl bg-red-50 px-5 py-2 text-base font-medium text-red-500  outline-none transition duration-200 hover:bg-red-600/5 active:bg-red-700/5 dark:bg-red-400/10 dark:text-white dark:hover:bg-red-300/10 dark:active:bg-red-200/10"
                >
                  {isLoading ? (
                    <DivLoader className="h-6 w-6 border-red-500" />
                  ) : (
                    "Delete"
                  )}
                </button>
              </div>
          </ModalFooter>
          </Card>
      </Modal>
    </>
  );
};
export default DeleteAccountModal;
