import Card from "components/card";
import Searchbox from "components/fields/Searchbox";
import React from "react";
import {
  PaginationState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";

import Pagination from "components/pagination";
import { BlackListClientsRowObj } from "..";
import DivLoader from "components/divloader/DivLoader";
import DeleteModal from "./DeleteBlockRecordModal";
import { deleteOrganization } from "api/graphQL_organizations";
import toast from "react-hot-toast";
import { deleteBlackListRecord } from "api/blackListClient";
import CreateBlockClientsModal from "./CreateBlockeclientModal";
import ViewHistoryModal from "./viewHistory";

function BlackList(props: {
  pageSize: any;
  setPageSize: any;
  page: any;
  setPage: any;
  totalpage: any;
  totalItems: any;
  currentPage: any;
  roleData: any;
  tableData: any;
  fetchBlackListClientsData: any;
  isLoading: boolean;
  setIsLoading: any;
  onValueChange?: any;
}) {
  const {
    pageSize,
    setPageSize,
    page,
    setPage,
    totalpage,
    totalItems,
    currentPage,
    roleData,
    tableData,
    fetchBlackListClientsData,
    isLoading,
    setIsLoading,
    onValueChange,
  } = props;
  let defaultData = tableData;

  const [{ pageIndex }, setPagination] = React.useState<PaginationState>({
    pageIndex: 0,
    pageSize,
  });

  const [data, setData] = React.useState(() => [...defaultData]);
  const [searchVal, setSearchVal] = React.useState<any>("");

  const pagination = React.useMemo(
    () => ({
      pageIndex,
      pageSize,
    }),
    [pageIndex, pageSize]
  );

  React.useEffect(() => {
    setData(tableData);
  }, [tableData]);

  const columns = [
    columnHelper.accessor("created_at", {
      id: "created_at",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Created At
        </p>
      ),
      sortDescFirst: true,
      cell: (info) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <p className="text-sm font-bold text-navy-700 dark:text-white">
                {info.getValue()}
              </p>
            )}
          </>
        );
      },
    }),

    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Bin + Last 4
        </p>
      ),
      sortDescFirst: true,
      cell: (info) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <p className="text-sm font-bold text-navy-700 dark:text-white">
                {`${info?.row?.original?.bin || ""}${
                  info?.row?.original?.last_four
                    ? "-" + info?.row?.original?.last_four
                    : ""
                }`}
              </p>
            )}
          </>
        );
      },
    }),

    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">Name</p>
      ),
      sortDescFirst: true,
      cell: (info) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <p className="text-sm font-bold text-navy-700 dark:text-white">
                {`${info?.row?.original?.first_name || ""} ${
                  info?.row?.original?.last_name || ""
                }`}
                {!info?.row?.original?.first_name ? "-" : ""}
              </p>
            )}
          </>
        );
      },
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">Email</p>
      ),
      sortDescFirst: true,
      cell: (info) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <p className="text-sm font-bold text-navy-700 dark:text-white">
                {info.getValue() || "-"}
              </p>
            )}
          </>
        );
      },
    }),

    columnHelper.accessor("attempts", {
      id: "attempts",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Attempts
        </p>
      ),
      sortDescFirst: true,
      cell: (info) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <p className="text-sm font-bold text-navy-700 dark:text-white">
                {info.getValue() || "-"}
              </p>
            )}
          </>
        );
      },
    }),

    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Action
        </p>
      ),
      sortDescFirst: true,
      cell: (info) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <p className="flex gap-2 text-sm font-bold text-navy-700 dark:text-white">
                {roleData?.[0]?.blacklist?.value?.edit_blacklist && (
                  <CreateBlockClientsModal
                    fetchBlackListClientsData={fetchBlackListClientsData}
                    id={info.getValue()}
                    roleData={roleData}
                    info={false}
                    infoData={info}
                  />
                )}
                <ViewHistoryModal
                  fetchBlackListClientsData={fetchBlackListClientsData}
                  id={info.getValue()}
                  info={true}
                  roleData={roleData}
                  infoData={info}
                  tableData={[]}
                />

                {roleData?.[0]?.blacklist?.value?.delete_blacklist && (
                  <DeleteModal
                    fetchListData={fetchBlackListClientsData}
                    id={info.getValue()}
                    name={info?.row?.original?.first_name ?? ""}
                    heading={"Delete Record"}
                    infoData={info}
                    message={`This will delete the ${
                      info?.row?.original?.first_name || info?.row?.original?.id
                    } which is irreversible action. Do you still want to continue?
              `}
                    handleDelete={handleDelete}
                  />
                )}
              </p>
            )}
          </>
        );
      },
    }),
  ];

  const columns2 = [
    columnHelper.accessor("created_at", {
      id: "created_at",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Created At
        </p>
      ),
      sortDescFirst: true,
      cell: (info) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <p className="text-sm font-bold text-navy-700 dark:text-white">
                {info.getValue()}
              </p>
            )}
          </>
        );
      },
    }),

    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Bin + Last 4
        </p>
      ),
      sortDescFirst: true,
      cell: (info) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <p className="text-sm font-bold text-navy-700 dark:text-white">
                {`${info?.row?.original?.bin || ""}${
                  info?.row?.original?.last_four
                    ? "-" + info?.row?.original?.last_four
                    : ""
                }`}
              </p>
            )}
          </>
        );
      },
    }),

    columnHelper.accessor("id", {
      id: "id",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">Name</p>
      ),
      sortDescFirst: true,
      cell: (info) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <p className="text-sm font-bold text-navy-700 dark:text-white">
                {`${info?.row?.original?.first_name || ""} ${
                  info?.row?.original?.last_name || ""
                }`}
                {!info?.row?.original?.first_name ? "-" : ""}
              </p>
            )}
          </>
        );
      },
    }),
    columnHelper.accessor("email", {
      id: "email",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">Email</p>
      ),
      sortDescFirst: true,
      cell: (info) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <p className="text-sm font-bold text-navy-700 dark:text-white">
                {info.getValue() || "-"}
              </p>
            )}
          </>
        );
      },
    }),

    columnHelper.accessor("attempts", {
      id: "attempts",
      header: () => (
        <p className="text-sm font-bold text-gray-900 dark:text-white">
          Attempts
        </p>
      ),
      sortDescFirst: true,
      cell: (info) => {
        return (
          <>
            {!info.row.getIsExpanded() && !info.row.getIsExpanded() && (
              <p className="text-sm font-bold text-navy-700 dark:text-white">
                {info.getValue() || "-"}
              </p>
            )}
          </>
        );
      },
    }),
  ];

  const table = useReactTable({
    data,
    columns:
      roleData?.[0]?.blacklist?.value?.edit_blacklist ||
      roleData?.[0]?.blacklist?.value?.view_blacklist ||
      roleData?.[0]?.blacklist?.value?.delete_blacklist
        ? columns
        : columns2,
    state: {
      pagination,
    },
    onPaginationChange: setPagination,
    //   getSubRows: (row) => row?.all_data,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    debugTable: true,
  });

  const handleDelete = (deleteId: string) => {
    setIsLoading(true);
    deleteBlackListRecord(deleteId)
      .then((data) => {
        fetchBlackListClientsData();
        toast.success(data?.message || "Record deleted");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.message ?? "Record deletion failed");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleValueChange = (e: any) => {
    onValueChange(e);
    setSearchVal(e);
  };

  return (
    <>
      <Card extra={"w-full h-full sm:overflow-auto px-6 mb-6"}>
        <header className="relative flex flex-wrap items-center justify-between pt-5">
          <div className="text-xl font-bold text-navy-700 dark:text-white">
            Blacklist
          </div>
          <Searchbox onSearch={handleValueChange} />
        </header>
        <div className="relative mt-4 overflow-x-auto overflow-x-auto shadow-md scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-track-rounded-full scrollbar-thumb-rounded-xl scrollbar-h-1.5 sm:rounded-lg">
          {props?.isLoading ? (
            <DivLoader className="m-5 h-6 w-6 border-indigo-500" />
          ) : (
            <table className="w-full w-full text-left text-sm text-gray-500 dark:text-gray-400 rtl:text-right">
              <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
                {table?.getHeaderGroups().map((headerGroup) => (
                  <tr
                    key={headerGroup.id}
                    className="!border-px !border-gray-400"
                  >
                    {headerGroup.headers?.map((header) => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          onClick={header.column.getToggleSortingHandler()}
                          className="cursor-pointer border-b-[1px] border-gray-200 pb-2  pt-4 text-start"
                        >
                          <div className="items-center justify-between px-2 text-xs text-gray-200">
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                            {{
                              asc: "",
                              desc: "",
                            }[header.column.getIsSorted() as string] ?? null}
                          </div>
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows?.length > 0 ? (
                  table.getRowModel().rows.map((row) => {
                    return (
                      <tr
                        key={row.id}
                        className={`border-b hover:bg-gray-50 dark:border-gray-700  dark:bg-gray-800 ${
                          row.getIsExpanded() ? "" : ""
                        } ${!row.getCanExpand() ? "" : ""} `}
                      >
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <td
                              key={cell.id}
                              className={`border-white/0 px-2 py-3`}
                            >
                              {flexRender(
                                cell.column.columnDef.cell,
                                cell.getContext()
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td colSpan={10}>
                      <p className="p-4 text-center">No records found.</p>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>

        <Pagination
          setPage={setPage}
          page={page}
          totalpage={totalpage}
          currentPage={currentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          arraySize={[50, 100, 200]}
        />
      </Card>
    </>
  );
}

export default BlackList;
const columnHelper = createColumnHelper<BlackListClientsRowObj>();
